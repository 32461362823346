import { Injectable, Inject, OnDestroy } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from './autnentication-service';
import { Environmenter } from './environmenter.service';
import { AppLoaderServiceMessaging } from '../Shared/app-loader-service-messaging';
import { Subscription } from 'rxjs';
import { GlobalService } from '../admin/GlobalService/global-service';
declare var $: any;
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor, OnDestroy {
  _environment: any;
  is_hide_loader: boolean = true;
  subscription: Subscription;
  loader_data: any;
  request_count: number = 0;
  constructor(private authService: AuthenticationService, private environment: Environmenter,
    private loader_messaging_service: AppLoaderServiceMessaging, private global: GlobalService) {
    this._environment = environment.getGlobalEnvironment();
    this.subscription = this.loader_messaging_service.show_loader().subscribe((data: boolean) => {
      this.is_hide_loader = data;
    });
  }


  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.request_count++;
    this.showLoader();
    var authReq = req.clone();
    this.authService.updateDataFromCache(this._environment[this._environment.rl_protocol]);
    if (this.authService._oauthData.isAuthenticated &&
      !this.isObjectEmpty(this.authService._oauthData.idToken) &&
      req.headers.get('scope-header') != 'x-auth-request') {
      authReq = req.clone({
        setHeaders: {
          'Authorization': this.authService._oauthData.idToken['access_token'],
          'p': btoa(JSON.stringify(this.authService._oauthData.user)),
          // 'X-Frame-Options': 'deny',
          // 'X-XSS-Protection': '1; mode=block',
          // 'Strict-Transport-Security': 'max-age=31536000',
          // 'X-Content-Type-Options': 'nosniff',
          // 'Set-Cookie': 'Secure'
        }
      });
    }
    return next.handle(authReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.request_count--;
            this.onEnd();

          }
        },
        error => {
          if (error.status == 400 || error.status == 500) {
            this.global.utilities.notify.error("Error occur while getting data.");
            this.request_count--;
            this.hideLoader();
          }
          else if (error.status == 0) {
            this.global.utilities.notify.error("Something went wrong, please try again");
            this.request_count--;
            this.hideLoader();
          }
          if (event instanceof HttpResponse) {
            this.request_count--;
            this.onEnd();
          }
        }
      )
    );
  }

  private onEnd(): void {
    if (this.request_count == 0) {
      this.hideLoader();
    }
  }
  private showLoader(): void {
    if (this.is_hide_loader === true) {
      $(".preloader").fadeIn();
    }
  }
  private hideLoader(): void {
    $(".preloader").fadeOut();
  }
  isObjectEmpty(obj: Object) {
    return Object.keys(obj).length === 0;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}