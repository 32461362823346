import { Injectable, Inject } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { AuthenticationService } from './autnentication-service';
import { Environmenter } from './environmenter.service';
import { UtilService } from '../Shared/utilities';


@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {
  _environment: any;
  _uniService: any;
  constructor(private authService: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute,
    private utilService: UtilService, private platformLocation: PlatformLocation, private environment: Environmenter) {
    this._environment = environment.getGlobalEnvironment();
    this._uniService = utilService;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.updateDataFromCache(this._environment[this._environment.rl_protocol]);
    if (!this.authService._oauthData.isAuthenticated) {
      if (state.url) {
        if (!this.authService._renewActive) {
          var loginStartPage = this._uniService.getBaseUrl() + state.url;
          if (loginStartPage !== null) {
            this._environment.cacheLocation.setItem('returnURL', loginStartPage);
          }
          this.authService.loginRedirect(this._environment[this._environment.rl_protocol]);
        }
      }
    }
    else {
      return true;
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
