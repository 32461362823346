import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../admin/GlobalService/global-service';

@Component({
  selector: 'app-test-xyz',
  templateUrl: './test-xyz.component.html',
  styleUrls: ['./test-xyz.component.css']
})
export class TestXyzComponent implements OnInit {
  response: any;
  key: any = 'Key';
  RequestID: any;
  Payload: any;

  constructor(private global: GlobalService) { }

  ngOnInit(): void {
  }
  Decrypte() {
    this.response =  JSON.stringify( this.global.utilities.Decrypt(this.Payload, this.key.split('|')[0],  this.key.split('|')[1]))

  }
}
