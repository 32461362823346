import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppLoaderServiceMessaging {
    private subject = new BehaviorSubject<any>(true);

    hide_loader(isshowhideloader) {
        this.subject.next(isshowhideloader);
    }

    show_loader(): Observable<any> {
        return this.subject.asObservable();
    }
}