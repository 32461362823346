import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './authentication/authentication-guard';
import { SamlauthenticationComponent } from './samlauthentication/samlauthentication.component';
import { TestXyzComponent } from './test-xyz/test-xyz.component';
const routes: Routes = [
  { path: 'login', pathMatch: "full", component: SamlauthenticationComponent },
  { path: 'test_xyz', pathMatch: "full", component: TestXyzComponent },
  { path: 'exams', loadChildren: () => import('../app/exam-client/exam-client.module').then(m => m.ExamClientModule) },
  { path: 'member', loadChildren: () => import('../app/member/member.module').then(m => m.MemberModule), canActivate: [AuthenticationGuard] },
  { path: ':lang/' + environment.authentication, loadChildren: './authentication/authentication.module#AuthenticationModule' },
  { path: ':lang/' + environment.support, loadChildren: './support/support.module#SupportModule', canActivate: [AuthenticationGuard] },
  { path: '', pathMatch: "full", redirectTo: 'en-us/' + environment.authentication + '/signin' },
  /*  { path: '', pathMatch: "full",redirectTo:'login' },
   { path: '**', loadChildren: './not-found/not-found.module#NotFoundModule' }, */

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
