import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationStart, Event, NavigationError, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from './authentication/autnentication-service';
import { Environmenter } from './authentication/environmenter.service';
import { ApplicationInsightsService } from './ApplicationInsightsService';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private _router = Subscription.EMPTY;
  _environment: any;

  title = 'K2M KIT';
  constructor(private router: Router
    , private sanitizer: DomSanitizer
    , private authService: AuthenticationService
    , private environment: Environmenter
    ,private _appInsightService:ApplicationInsightsService
    
  ) {

    this._environment = environment.getGlobalEnvironment();
    this._router = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        
      }
      if (event instanceof NavigationEnd) {
      }
      if (event instanceof NavigationError) {
        //this.sanitizer.sanitize(1, this.router.navigate(['en-us/security/signin']));
        //let urll = this.sanitizer.bypassSecurityTrustUrl(event.url);
        this.authService.loginRedirect('');
      }
    });
    this._appInsightService.logPageView('Return Reason', this.router.url);
  }
}
