//import { ro_permission, packaging_permission } from '../Transaction/repair-order-transaction/Repair-order/repair-order-detail/repair-order.model';
import { Injectable } from '@angular/core';
import { GlobalService } from '../admin/GlobalService/global-service';
import { AppLoaderServiceMessaging } from './app-loader-service-messaging';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
export class helper {
  payload: string;
  spName: string;
  org_id:number;
  Guid:string;
}

export class DDLBinding {
  id: number;
  name: string
}

export class module_permission {

  function_code: string;
  module_type: string;
  is_applicable: boolean
  
 
  is_login_user_has_write_permission(permission: module_permission[]) {
    let module_per = permission.find(c => c.function_code == 'WM001');
    if (module_per.function_code === 'WM001' && module_per.is_applicable === true) {
      return true;
    }
    else {
      return false;
    }
  }

  is_login_user_has_approve_permission(permission: module_permission[]) {
   
    let module_per = permission.find(c => c.function_code == 'AP001');
    if (module_per && module_per.function_code === 'AP001' && module_per.is_applicable === true) {
      return true;
    }
    else {
      return false;
    }
  }
 


}

@Injectable({
  providedIn: 'root'
})
export class globalcode_modulevalidation {
  //created propery for holding permissions json.
  private _permissionJson: module_permission[];
  public get permissionJson(): module_permission[] {
    //this._permissionJson = this.global.utilities.storage.get('previousUrl');
    return this._permissionJson;
  }
  // public set permissionJson(v: string) {
  //   this.global.utilities.storage.set('previousUrl', v);
  //   this._permissionJson = v;
  // }

  count: number;

  message: string;
  helperdata = new helper();
  constructor(private global: GlobalService, private _loader_service: AppLoaderServiceMessaging) {

  }
  public IsCustomerCodeDuplicated(param: any) {
    this.helperdata.spName = "usp_get_IsCustomer_Code_Duplicated";
    this.helperdata.payload = JSON.stringify(param);

    return this.global.ServiceManager.request.post('Global/GetDataFromServer', this.helperdata);

  }
  get_permission_by_module() {
    var param: any = {};
    let is_login_user_has_write_permission = false;
    let helperdata = new helper();
    let permission = new module_permission();
    param.user_id = JSON.parse(this.global.utilities.storage.get(environment[environment.rl_protocol].applicationId)).user.id;
    param.module_code = this.global.module_code;

    helperdata.spName = "get_permission_via_module";
    helperdata.payload = JSON.stringify(param);
    // return this.global.ServiceManager.request.post('Global/GetDataFromServer', helperdata).toPromise();
    // use another way of promise object
    var promise = new Promise((resolve, reject) => {
      this.global.ServiceManager.request.post('Global/GetDataFromServer', helperdata).subscribe((result) => {
        this._permissionJson = JSON.parse(result.data.dataset.table[0].module_permission);
        resolve(result);
      }, (error) => {
        reject(error);
      });
    });

    return promise;

  }
}


export class TypedJson {
  static parse<T>(value: any): T {
    if (typeof value === "object" || value === undefined || value === '')
      return value;
    return JSON.parse(value) as T;
  }
}


export class AutoCompleteModel {
  source_type: string;
  source_name: string;
  value_column: string;
  merge_column?: string;
  id_column: string;
  condition?: string;
  search_key?: string;
  search_by: string;
  placeholder: string;
  sortby: string;
  sortdirection?: string;
  isDisable?: boolean;
  other_columns?: string;
  constructor()
  constructor(_obj: AutoCompleteModel)
  constructor(_obj?: any) {
    this.sortdirection = _obj && _obj.sortdirection || 'asc';
    this.sortby = _obj && _obj.sortby || '';
    this.placeholder = _obj && _obj.placeholder || '';
    this.search_by = _obj && _obj.search_by || '';
    this.source_type = _obj && _obj.source_type || '';
    this.source_name = _obj && _obj.source_name || '';
    this.value_column = _obj && _obj.value_column || '';
    this.merge_column = _obj && _obj.merge_column || '';
    this.id_column = _obj && _obj.id_column || '';
    this.condition = _obj && _obj.condition || '';
    this.search_key = _obj && _obj.search_key || '';
    this.other_columns = _obj && _obj.other_columns || '';
    this.isDisable = _obj && _obj.isDisable || false;
  }

}


export class SelectedAutoCompleteModel {
  id: number;
  value: string;
  display_value?: string;
  constructor()
  constructor(_obj: AutoCompleteModel)
  constructor(_obj?: any) {
    this.id = _obj && _obj.id || 0;
    this.value = _obj && _obj.value || '';
    this.display_value = _obj && _obj.display_value || '';
  }
}
/**
 * this model for holding data from service in component based service 
 */
export class DataResult {
  code: string;
  message: string;
  result: any;
}